<template>
  <header>
    <div class="header-info" :class="{ scroll: isScroll }">
      <div class="info">
        <a href="/" class="logo">
          <img src="@/assets/sys/logo-mobile@2x.png" alt="">
        </a>
        <div class="nav-list">
          <div class="nav-item">
            <router-link to="/">首页</router-link>
          </div>
          <div class="nav-item" v-for="item in typeText" :key="item.typeId">
            <router-link :to="item.to">{{ item.name }}</router-link>
            <div class="nav-child">
              <div @click="navToInfo(item, it)" class="b-item" v-for="(it, idx) in item.list" :key="idx">
                {{ it.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="nav-show" @click="showLeft">
          <i class="el-icon-s-fold"></i>
        </div>
      </div>
      <div class="mobile-nav" v-show="showLeftNav" :class="{ move: showLeftMove }">
        <p class="bg" @click="hideLeft"></p>
        <div class="nav">
          <div class="title">
            <span>导航栏目</span>
            <div class="close" @click="hideLeft">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <ul class="list">
            <li>
              <div to="/" class="level-1" @click="navLink('/')">首页</div>
            </li>
            <li v-for="item in typeText" :key="item.typeId">
              <div class="level-1" :to="item.to" @click="navLink(item.to)">{{ item.name }}</div>
              <div class="level-2" @click="navToInfo(item, it)" v-for="(it, idx) in item.list" :key="idx"> {{ it.name }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { articleCategoryList } from '@/api/index'
export default {
  name: 'pageHeader',

  data () {
    return {
      isScroll: false,
      showBg: false,
      typeId: 1,
      isshow: false,
      showLeftNav: false,
      showLeftMove: false,
      typeText: [
        {
          typeId: 5,
          name: '关于中慧信能',
          to: '/introduction',
          describe:
            '中慧信能技术有限公司于2013年成立，注册资金5000万元，分支机构主要分布在北京、广州、深圳、东莞、佛山、海口、重庆、贵阳等地；业务范围主要有智慧城市、智能建筑、光伏、储能、数据中心、5G专网、传输网工程、无线网工程、核心网工程的设计和集成。',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 2,
          name: '解决方案',
          to: '/servers',
          describe:
            '我们拥有一支经验丰富、技术精湛的专业团队，始终秉承以客户为中心的服务理念，致力于为客户提供最优质的技术解决方案和实施服务。我们相信，只有客户的满意，才是我们前进的动力。',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 1,
          name: '新闻中心',
          to: '/news',
          describe:
            '无论您是对我们的公司动态感兴趣，还是对行业的发展趋势和技术创新充满好奇，我们的新闻中心都能够满足您的需求。我们希望通过我们的新闻中心，与您分享我们的成就和行业的最新动态。',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 4,
          name: '项目招标',
          to: '/scientific',
          describe:
            '我们公司作为一家专业的解决方案提供商，经常参与各种项目招标活动。项目招标是一种公开、公平、公正的采购方式，通过招标方式选择最优秀的合作伙伴来共同完成项目。',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 6,
          name: '资源共享',
          to: '/resource?category_id=10009',
          describe:
            '作为一家领先的科技公司，我们始终秉持着开放、合作、共赢的原则，致力于为客户提供优质的资源共享服务。我们期待您的加入，一起共享资源，共创美好未来！',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 7,
          name: '联系我们',
          to: '/contactus',
          describe: '秉承专业、创新、精良、服务为本',
          img: require('@/assets/sys/index-ad-1.jpg')
        },
        {
          typeId: 8,
          name: '加入我们',
          to: '/talentCultivation',
          describe:
            '公司成立于2013年，业务范围覆盖智慧城市、智能建筑、光伏、储能、数据中心、5G专网等领域。 我们现有北京、广州、深圳、东莞、佛山、海口、重庆、贵阳等分支机构，拥有稳定的200名专业技术人员，持证上岗，技能过硬、责任心强。欢迎您加入我们的技术团队，共同成就行业精英！',
          img: require('@/assets/sys/index-ad-1.jpg')
        }
      ],
      describe: {
        typeId: 5,
        to: '/introduction'
      },
      defaultList: [
        { category_id: 100, is_login: 0, name: '商务合作', type: 7 },
        { category_id: 101, is_login: 0, name: '意见反馈', type: 7 },
        { category_id: 102, is_login: 0, name: '人才理念', type: 8 },
        { category_id: 103, is_login: 0, name: '薪酬福利', type: 8 },
        { category_id: 104, is_login: 0, name: '招聘职位', type: 8 }
      ],
      typeList: [],
      time: null
    }
  },

  computed: {
    token () {
      return this.$store.getters.token
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    $route (to) {
      this.showBg = to.meta.showBg
      // this.hideNav = to.meta.hideNav
      this.isshow = false
    }
  },

  created () {
    articleCategoryList().then((result) => {
      const list = [...result.data.list, ...this.defaultList]
      this.typeText = this.typeText.map((e) => {
        e.list = list.filter((f) => f.type === e.typeId)
        return e
      })
    })
  },

  methods: {
    showLeft () {
      this.showLeftNav = true
      setTimeout(() => {
        this.showLeftMove = true
      }, 50)
    },

    navLink (path) {
      this.hideLeft()
      this.$router.push({ path })
    },

    hideLeft () {
      this.showLeftMove = false
      setTimeout(() => {
        this.showLeftNav = false
      }, 300)
    },

    navToInfo ({ to }, { name, category_id, is_login }) {
      this.hideLeft()
      if (!this.token && is_login) {
        this.$message.error('请先登录')
        this.$router.push({ path: '/login' })
        return
      }
      this.$router.replace({
        path: to,
        query: { category_id }
      })
      this.$nextTick(() => {
        setTimeout(() => {
          this.getDom(name)
        }, 500)
      })
    },

    getDom (name) {
      const dom = document.getElementById(name)
      if (dom) {
        const top = dom.offsetTop
        document.documentElement.scrollTop = top - 200
      }
    },

    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      this.isScroll = scrollTop > 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
header {
  .header-info {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.5s all;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9) !important;
      background: linear-gradient(to bottom,
          #fbfcfc 0%,
          #f2f2f2 100%) !important;

      .info {
        .nav-item {
          a {
            color: #030303;
          }
        }
      }
    }

    &.scroll {
      // background-color: rgba(0, 0, 0, 0.3);
      background-color: #fff !important;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

      .info {
        .nav-item {
          a {
            color: #030303;
          }
        }

        .nav-show {
          color: #de6a27;
        }
      }
    }

    &.showBg {
      background-image: url("@/assets/sys/nav-bg@2x.png");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;

      .info {
        .logo {
          // background-image: url("@/assets/sys/logo-2@2x.png");
        }

        .nav-item {
          a {
            color: #ffffff;
          }

          &::after {
            background-color: #fefefe;
          }
        }
      }
    }

    .info {
      width: 1440px;
      margin: auto;
      height: 100px;
      // padding: 25px 0;
      display: flex;
      align-items: center;

      .logo {
        img {
          width: 340px;
        }
      }

      .nav-list {
        display: flex;
        align-items: center;
        margin-left: auto;
      }

      .nav-item {
        font-size: 18px;
        height: 100px;
        font-weight: 600;
        padding: 25px 0;
        position: relative;
        margin: 0 18px;

        .nav-child {
          position: absolute;
          top: 100px;
          background-color: #f0f7fc;
          padding: 0 10px;
          min-width: 150px;
          text-align: center;
          left: 50%;
          transform: translateX(-50%);
          color: #333333;
          max-height: 0;
          overflow: hidden;
          transition: 0.3s max-height;
          font-weight: 400;
          font-size: 16px;

          .b-item {
            line-height: 50px;
            cursor: pointer;
            word-break: keep-all;
            border-bottom: 1px dashed #dedede;

            &:last-child {
              border: 0;
            }

            &:hover {
              color: #de6a27;
            }
          }

          // display: none;
        }

        a {
          color: #ffffff;
          display: block;
          line-height: 50px;
        }

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          background: #de6a27;
          left: 0;
          bottom: 0px;
          transition: 0.5s all;
        }

        &:hover {
          &::after {
            width: 100%;
          }

          .nav-child {
            max-height: 300px;
          }
        }

        &.router-link-exact-active {
          &::after {
            width: 100%;
          }
        }
      }

      .nav-show {
        display: none;
      }
    }

    .mobile-nav {
      position: fixed;
      z-index: 110;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;

      &.move {
        .bg {
          opacity: 1;
        }

        .nav {
          transform: translateX(0);
        }
      }

      .bg {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: 0.3s all;
      }

      .nav {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: 60%;
        display: flex;
        flex-direction: column;
        transition: 0.3s all;
        transform: translateX(100%);

        .title {
          height: 60px;
          flex-shrink: 0;
          background-color: #de6a27;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          color: #ffffff;

          span {
            font-size: 17px;
          }

          .close {
            font-size: 26px;
            padding: 5px 0 5px 20px;
          }
        }

        .list {
          flex: 1;
          overflow-y: auto;
          font-size: 15px;
          background-color: #ffffff;

          li {
            border-bottom: 1px solid #ededed;

            .level-1 {
              height: 50px;
              line-height: 50px;
              padding: 0 20px;
              display: block;
            }

            .level-2 {
              height: 50px;
              line-height: 50px;
              padding: 0 20px 0 40px;
              font-size: 14px;
              color: #666666;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  header {
    height: 50px;

    .header-info {
      position: relative;
      background-color: #ffffff !important;

      &:hover {
        background-color: #ffffff !important;
        background: #ffffff !important;
      }

      &.scroll {
        background-color: #ffffff !important;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
      }

      .info {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;

        .logo {
          img {
            width: auto;
            height: 30px;
          }
        }

        .nav-list {
          display: none;
        }

        .nav-show {
          display: block;
          margin-left: auto;
          color: #de6a27;
          font-size: 30px;
          padding: 5px 0 5px 20px;
        }
      }
    }
  }
}
</style>
