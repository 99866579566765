import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
    meta: {
      showBg: false
    }
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/introduction/index.vue'),
    meta: {
      showBg: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/search.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/index.vue'),
    children: [
      {
        path: 'collectJobs',
        name: 'collectJobs',
        component: () => import(/* webpackChunkName: "about" */ '../views/my/collectJobs.vue')
      }

    ]
  },
  {
    path: '/myArticle',
    name: 'myArticle',
    component: () => import(/* webpackChunkName: "about" */ '../views/my/article.vue')
  },

  {
    path: '/jobInfo',
    name: 'jobInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/job/info.vue')
  },
  {
    path: '/news',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hideNav: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/researchResult',
    name: 'researchResult',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/index/researchResult.vue')
  },
  {
    path: '/servers',
    name: 'servers',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/servers/index.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/solution/index.vue')
  },
  {
    path: '/resource',
    name: 'resource',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/resource/index.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/contactus/index.vue')
  },
  {
    path: '/talentCultivation',
    name: 'talentCultivation',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/talentCultivation/index.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperation/index.vue')
  },
  {
    path: '/cooperationDetail',
    name: 'cooperationDetail',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperation/detail.vue')
  },
  {
    path: '/scientific',
    name: 'scientific',
    meta: {
      showBg: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/scientific/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
