import request from '@/utils/request'

const loginApi = {
  Login: 'passport/login',
  sendSmsCaptcha: 'captcha/sendSmsCaptcha',
  loginWebByWx: 'passport/loginWebByWx',
  loginByPwd: 'passport/loginByPwd'
}

export function loginByPwd (parameter) {
  return request({
    url: loginApi.loginByPwd,
    method: 'post',
    data: parameter
  })
}

export function loginWebByWx (parameter) {
  return request({
    url: loginApi.loginWebByWx,
    method: 'post',
    data: parameter
  })
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: loginApi.Login,
    method: 'post',
    data: parameter
  })
}

export function sendSmsCaptcha (parameter) {
  return request({
    url: loginApi.sendSmsCaptcha,
    method: 'post',
    data: parameter
  })
}
