<template>
  <div id="app">
    <pageHeader />
    <div class="page-wrap">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <footer>
      <div class="top">
        <div class="info">
          <div class="step-1">
            <img src="@/assets/sys/logo@2x.png" class="logo" alt="" />
            <div class="code-list">
              <el-popover placement="bottom" width="176" trigger="hover">
                <img src="@/assets/sys/wx-code.jpg" style="width: 150px" class="code-img" alt="" />
                <a href="javascript:;" slot="reference" class="item">
                  <img src="@/assets/sys/foot-ico4.png" class="icon" alt="" />
                </a>
              </el-popover>
              <el-popover placement="bottom" width="176" trigger="hover">
                <img src="@/assets/sys/dy-code.jpg" style="width: 150px" class="code-img" alt="" />
                <a href="javascript:;" slot="reference" class="item">
                  <img src="@/assets/sys/douyin.png" class="icon" alt="" />
                </a>
              </el-popover>
            </div>
          </div>
          <div class="step-2">
            <div class="list">
              <div class="item" v-for="item in navList" :key="item.typeId">
                <router-link :to="item.to" class="name">{{ item.name }}</router-link>
                <a href="javascript:;" @click="navToInfo(item, it)" class="nav-item" v-for="it in item.list"
                  :key="it.category_id">{{ it.name }}</a>
              </div>
              <div class="item contact-us">
                <a href="#/contactus" class="name">联系我们</a><a href="javascript:;" class="nav-item">
                  <span class="text">邮箱： business@zhiet.net</span></a><a href="javascript:;" class="nav-item"><span
                    class="text">联系电话： 020-81076934</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="content">
          <p class="lanel">
            ICP备案号：黔ICP备2021009319号-2 贵公网安备 52011502002449号
          </p>
          <p class="value">Copyright © 2023 中慧信能技术有限公司 版权所有</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import { articleCategoryList } from '@/api/index'

export default {
  components: {
    pageHeader
  },

  data () {
    return {
      allChild: [],
      navList: [
        {
          typeId: 5,
          name: '关于中慧信能',
          to: '/introduction',
          list: []
        },
        {
          typeId: 2,
          name: '解决方案',
          to: '/servers',
          list: []
        },
        {
          typeId: 1,
          name: '新闻中心',
          to: '/news',
          list: []
        },
        {
          typeId: 4,
          name: '项目招标',
          to: '/scientific',
          list: []
        },
        {
          typeId: 6,
          name: '资源共享',
          to: '/resource',
          list: []
        },
        {
          typeId: 8,
          name: '加入我们',
          to: '/talentCultivation',
          list: []
        }
      ],
      defaultList: [
        { category_id: 102, is_login: 0, name: '人才理念', type: 8 },
        { category_id: 103, is_login: 0, name: '薪酬福利', type: 8 },
        { category_id: 104, is_login: 0, name: '招聘职位', type: 8 }
      ]
    }
  },

  computed: {
    token () {
      return this.$store.getters.token
    }
  },

  methods: {
    navToInfo (activeItem, { name, category_id, is_login }) {
      if (!this.token && is_login) {
        this.$message.error('请先登录')
        this.$router.push({ path: '/login' })
        return
      }
      this.$router.replace({ path: activeItem.to, query: { category_id } })
      this.$nextTick(() => {
        setTimeout(() => {
          this.getDom(name)
        }, 500)
      })
    },

    getDom (name) {
      const dom = document.getElementById(name)
      if (dom) {
        const top = dom.offsetTop
        document.documentElement.scrollTop = top - 200
      }
    }
  },

  created () {
    articleCategoryList().then((result) => {
      const list = [...result.data.list, ...this.defaultList]
      this.navList = this.navList.map((e) => {
        e.list = list.filter((f) => e.typeId === f.type)
        return e
      })
    })
  }
}
</script>

<style lang="less">
@import "./assets/css/reset.less";

html,
body {
  margin: 0;
  background-color: #ffffff;
  min-width: 1440px;
  color: #030303;
  font-family: "PingFang SC", "Microsoft YaHe", "Microsoft YaHei UI",
    "WenQuanYi Micro Hei", Arial, "sans-serif", "宋体";
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.page-wrap {
  flex: 1;
}

/*设置宽度,轨道颜色*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #c6c6c6;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5e6272;
  text-align: center;

  .emptyimg {
    width: 175px;
  }

  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}

.in-label {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #0b1d28;
  margin-bottom: 40px;

  .icon-tri {
    width: 24px;
    height: 24px;
  }
}

// .page-wrap {
//  padding-top: 132px;
// }
.page-content {
  width: 1440px;
  margin: 0 auto;
}

footer {
  position: relative;
  z-index: 99;
  background: #f5f5f5;

  .top {
    .info {
      width: 1440px;
      margin: auto;
      padding: 30px 0;

      .step-1 {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .logo {
          width: 419px;
          display: block;
        }

        .about-us {
          line-height: 24px;
          text-indent: 2em;
          color: #999;
          margin: 10px 0px 10px;
        }

        .contact-us {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
              color: #999999;
            }
          }

          .goto-ico {
            font-size: 20px;
            margin-right: 10px;
            color: #999999;
          }
        }

        .code-list {
          display: flex;
          margin: 0px 0px 0px;
          justify-content: center;
          align-content: center;

          .item {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #666;
            border-radius: 50%;
            margin: 30px 20px;

            .icon {
              width: 30px;
            }
          }
        }

        .download {
          display: flex;
          margin-top: 36px;

          .item {
            width: 289px;
            height: 75px;
            margin-right: 36px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            padding-left: 30px;
            box-sizing: border-box;

            &:last-child {
              margin-right: 0;
            }

            img {
              width: 40px;
              margin-right: 16px;
            }
          }
        }
      }

      .step-2 {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;

        .list {
          margin-top: 12px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item {
            margin: 0 70px 40px 0;

            &:nth-child(7n + 7) {
              margin: 0 0 40px 0;
            }
          }

          // .contact-us {
          //   i {
          //     margin-left: 10px;
          //   }
          // }

          .name {
            font-size: 20px;
            color: #333333;
            margin-bottom: 20px;
          }

          .nav-item {
            display: block;
            margin: 20px 0px 0 0;
            color: #666666;
            font-size: 14px;
            line-height: 22px;
            text-align: center;

            // .text {
            //   margin-left: 10px;
            // }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .copyright {
    background: #eaeaea;
  }

  .content {
    text-align: center;
    padding: 32px 0px 20px;
    display: flex;

    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0px auto;

    p {
      font-size: 14px;
      line-height: 24px;
      color: #999999;
    }
  }
}

// .el-button--primary {
//   background: #00b5a3 !important;
//   border-color: #00b5a3 !important;
// }
.list {
  .el-collapse-item__header {
    font-size: 20px !important;
    color: #030303;
    padding: 50px 0px;
  }
}

.connect {
  width: 1440px;
  height: 568px;
  box-sizing: border-box;
  padding: 124px 160px;
  background-image: url("@/assets/sys/connect-bg@2x.jpg");
  background-size: 100%;
  margin: 80px auto;

  .name {
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
  }

  .title {
    font-size: 34px;
    line-height: 48px;
    margin-top: 16px;
    font-weight: 600;
  }

  .text {
    margin-top: 16px;
    font-size: 15px;
    line-height: 30px;
    width: 300px;
  }

  .methods {
    display: flex;
    align-items: flex-end;
    margin-top: 60px;

    .item {
      margin-right: 40px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;

      .icon {
        width: 44px;
        margin-bottom: 12px;
      }
    }

    .code {
      margin-left: auto;
      width: 120px;
    }
  }
}

.page-heade {
  background-image: url("@/assets/sys/nav-bg@2x.png");
  background-size: cover;
  height: 450px;
  background-position: top;
  background-repeat: no-repeat;
  margin-top: -125px;
  box-sizing: border-box;
  padding: 300px 0 0 0;

  .name {
    width: 1440px;
    margin: auto;
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }
}

@media (max-width: 960px) {
  html, body {
    min-width: auto;
    width: 100%;
  }
  /*设置宽度,轨道颜色*/
  ::-webkit-scrollbar {
    width: 2px;
    background: #c6c6c6;
    /*可以设置轨道颜色*/
    padding-right: 2px;
  }

  /*滚动条*/
  ::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
  }

  footer {
    .top {
      display: none;
      .info {
        width: auto;
        padding: 0;
        .step-1 {
          .logo {
            height: 70px;
            width: auto;
          }
          .code-list {
            .item {
              width: 40px;
              height: 40px;
              margin: 20px 10px;
              .icon {
                width: 25px;
              }
            }
          }
        }
        .step-2 {
          padding: 0 20px;
          .list {
            .item {
              width: 33.33%;
              margin: 0 0 40px 0;
              text-align: center;
              &.contact-us {
                .nav-item {
                  white-space: nowrap;
                  padding-left: 30px;
                }
              }
              .name {
                font-size: 15px;
                font-weight: 600;
              }
              .nav-item {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .content {
      padding: 10px 20px;
      display: block;
      background-color: #333333;
      p {
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
}
</style>
