import request from '@/utils/request'

const indexApi = {
  detail: 'page/detail',
  articleList: 'article/list',
  articleCategoryList: 'article.category/list',
  talentRecruitmentList: 'talentRecruitment/list',
  friendshipList: 'friendship/list',
  image: 'captcha/image',
  submitFeedback: 'message/submitFeedback',
  copywriting: 'copywriting/detail',
  storeInfo: 'page/storeInfo',
  companies: 'companies/list',
  introductionList: 'introduction/list',
  companyInformation: 'introduction/companyInformation'
}

export function companyInformation (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.companyInformation, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

export function introductionList (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.introductionList, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

export function companies (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.companies, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

export function storeInfo (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.storeInfo, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

export function copywriting (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.copywriting, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

export function submitFeedback (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.submitFeedback, // 请求的API路径
    method: 'post', // 请求方法为GET
    data: parameter // 将参数对象传递给请求
  })
}

export function getImage (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.image, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

/**
 * 获取详细信息的接口调用函数
 * @param parameter {'articleId':10001} - 请求参数对象
 * @returns 返回一个请求Promise对象，用于获取详细信息
 */
export function detail (parameter) {
  // 使用request函数发起GET请求，获取详细信息
  return request({
    url: indexApi.detail, // 请求的API路径
    method: 'get', // 请求方法为GET
    params: parameter // 将参数对象传递给请求
  })
}

/**
 * 获取文章列表
 * @param parameter {'categoryId':10001} 请求参数对象，用于传递给请求的参数
 * @return {Promise} 返回一个Promise对象，用于处理请求的结果
 */
export function articleList (parameter) {
  return request({
    url: indexApi.articleList,
    method: 'get',
    params: parameter
  })
}

/**
 * 获取文章分类列表
 * @param parameter {'type':1} #分类列表  1.新闻 2.案例 3.产品 4.投标 5.公司 6.资料
 * @return 返回一个Promise对象，它解析为请求结果
 */
export function articleCategoryList (parameter) {
  // 发起一个GET请求，获取文章分类列表
  return request({
    url: indexApi.articleCategoryList, // 请求的API路径
    method: 'get', // 请求方法
    params: parameter // 请求参数
  })
}

/**
 * 获取人才招聘列表
 * @param parameter 请求参数对象，用于传递给请求的参数
 * @return 返回一个Promise对象，它解析为请求结果
 */
export function talentRecruitmentList (parameter) {
  // 发起GET请求以获取人才招聘列表
  return request({
    url: indexApi.talentRecruitmentList, // 指定请求的API路径
    method: 'get', // 使用GET方法请求
    params: parameter // 将参数对象传递给请求
  })
}

export function friendshipList (parameter) {
  // 发起GET请求以获取人才招聘列表
  return request({
    url: indexApi.friendshipList, // 指定请求的API路径
    method: 'get', // 使用GET方法请求
    params: parameter // 将参数对象传递给请求
  })
}
